import { FC } from 'preact/compat';
import React from 'react';

import { DribbbleShot } from '@/components/diji-blog-holding-site/diji-blog-holding-site';
import DribbleWrapper from '@/components/diji-blog-holding-site/Dribbble/DribbbleWrapper';

interface DribbblesProps {
  dribbbleShots?: DribbbleShot[];
}

const Dribbbles: FC<DribbblesProps> = ({ dribbbleShots }) => {
  const step = 11;
  const renderedComponents = [];
  if (!dribbbleShots || !dribbbleShots.length) return null;
  for (let i = 0; i < Math.ceil(dribbbleShots.length / step); i++) {
    const index = i * step;
    renderedComponents.push(
      <DribbleWrapper
        key={`${i}-1`}
        dribbbleShots={dribbbleShots.slice(index, index + 2)}
        position={1}
        initialIndex={1}
      />,
      <DribbleWrapper
        key={`${i}-3`}
        dribbbleShots={dribbbleShots.slice(index + 2, index + 3)}
        position={2}
        initialIndex={3}
      />,
      <DribbleWrapper
        key={`${i}-4`}
        dribbbleShots={dribbbleShots.slice(index + 3, index + 5)}
        position={3}
        initialIndex={4}
      />,
      <DribbleWrapper
        key={`${i}-6`}
        dribbbleShots={dribbbleShots.slice(index + 5, index + 7)}
        position={4}
        initialIndex={6}
      />,
      <DribbleWrapper
        key={`${i}-8`}
        dribbbleShots={dribbbleShots.slice(index + 7, index + 8)}
        position={5}
        initialIndex={8}
      />,
      <DribbleWrapper
        key={`${i}-9`}
        dribbbleShots={dribbbleShots.slice(index + 8, index + 9)}
        position={6}
        initialIndex={9}
      >
        <DribbleWrapper
          dribbbleShots={dribbbleShots.slice(index + 9, index + 11)}
          position={6}
          initialIndex={10}
          className="selected-image-right"
        />
      </DribbleWrapper>
    );
  }
  return <>{renderedComponents}</>;
};

export default Dribbbles;
