import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { DribbbleShot } from '@/components/diji-blog-holding-site/diji-blog-holding-site';
import Dribble from '@/components/diji-blog-holding-site/Dribbble/Dribble';

const DribbleWrapper = ({
  dribbbleShots,
  position,
  initialIndex,
  className,
  children,
}: PropsWithChildren<{
  dribbbleShots: DribbbleShot[];
  position: number;
  initialIndex: number;
  className?: string;
}>): JSX.Element | null => {
  if (!dribbbleShots || !dribbbleShots.length) return null;
  return (
    <div className={className || classNames('selected-image-wrapper', `+${position}`)}>
      {dribbbleShots.map((dribbleShot, index) => (
        <Dribble key={index} dribbbleShot={dribbleShot} index={initialIndex + index} />
      ))}
      {children}
    </div>
  );
};
export default DribbleWrapper;
