import classNames from 'classnames';
import { Cubic, TimelineLite, TimelineMax, TweenMax } from 'gsap';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as ScrollMagic from 'scrollmagic-with-ssr';

import Dribbbles from '@/components/diji-blog-holding-site/Dribbble/Dribbbles';
import Spinner from '@/components/spinner/spinner';
import useLetsTalkForm from '@/hooks/useLetsTalkForm';
import { useWindowSize } from '@/hooks/useWindowSize';
import Navigation from '@/modules/navigation';
import BlueDiji from '@/public/images/blue_diji.svg';
import HalfWhiteDiji from '@/public/images/half_white_diji.svg';
import OutlinedDiji from '@/public/images/outlined_diji.svg';
import { NavigationContext } from '@/shared/NavigationContext';
import { goTo } from '@/utils/Page';

import mainStyles from './main.module.scss';
import styles from './style.module.scss';

export interface DribbbleShot {
  image?: string;
  url?: string;
}

export interface DijiBlogHoldingSiteProps {
  dribbbleShots?: DribbbleShot[];
  bringingIdeas?: {
    word?: string;
  }[];
}

const displayRatioBottom = 0.66;
const offset = 150;
const duration = 0.75;
const ease = Cubic.easeInOut;

let interval: NodeJS.Timer | null = null;

const triggerSelectedWorkConfig = function () {
  return {
    triggerElement: '#main-content',
    triggerHook: 'onLeave',
    offset: document.getElementById('main-content')?.clientHeight,
  };
};

const DijiBlogHoldingSite: FC<DijiBlogHoldingSiteProps> = ({
  dribbbleShots,
  bringingIdeas = [],
}) => {
  const { formData, onChange, onSubmit, error, successMessage, loading } = useLetsTalkForm();
  const { setTheme, theme } = useContext(NavigationContext);
  const persisTheme = useRef(theme);
  const animation = useRef<TimelineLite | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const controller = useRef<any | null>(null);
  const screens = useRef<any[]>([]);
  const [numberOfBackgrounds, setNumberOfBackgrounds] = useState<number>(0);
  const [space, setSpace] = useState<number>(0);
  const { width } = useWindowSize();
  const router = useRouter();
  const [isInitDone, setIsInitDone] = useState(false);

  useEffect(() => {
    persisTheme.current = theme;
  }, [theme]);

  useEffect(() => {
    if (router.asPath.includes('#let-talk') && isInitDone) {
      onLetTalkClicked();
    }
  }, [isInitDone]);

  function getNext(word: Element): Element | null {
    return word.nextElementSibling;
  }

  function getVisible(): Element | undefined {
    return document.getElementsByClassName('is-visible')[0];
  }

  function getFirst(): Element | undefined {
    return document.getElementsByClassName('words-wrapper')[0].children[0];
  }

  function switchWords(current: Element, next: Element): void {
    current.classList.remove('is-visible');
    current.classList.add('is-hidden');

    next.classList.remove('is-hidden');
    next.classList.add('is-visible');
  }

  function getStarted() {
    const first = getVisible();
    if (first) {
      const next = getNext(first) || getFirst();
      if (next) {
        switchWords(first, next);
      } else {
        first.classList.remove('is-visible');
        first.classList.add('is-hidden');

        const newEl = getFirst();
        if (newEl) {
          newEl.classList.remove('is-hidden');
          newEl.classList.add('is-visible');
        }
      }
    }
  }

  function initWords() {
    setTimeout(function () {
      if (interval) clearInterval(interval);
      interval = setInterval(function () {
        getStarted();
      }, 2000);
    }, 2000);
  }

  function onSelectsWorksButtonClick() {
    goTo(document.getElementById('selected-works')?.getBoundingClientRect().top);
  }

  function goToTop() {
    goTo(0);
  }

  function onLetTalkClicked() {
    if (!isClicked) {
      setTheme('light');
      animation.current?.play();
      setIsClicked(true);
    } else {
      if (window.scrollY === 0) {
        setTimeout(() => {
          animation.current?.reverse(duration);
          setTheme('dark');
          setIsClicked(false);
        }, 0);
      } else {
        goTo(0, () => {
          setTimeout(() => {
            animation.current?.reverse(duration);
            setTheme('dark');
            setIsClicked(false);
          }, duration);
        });
      }
    }
  }

  function transitionMainBanner() {
    screens.current.push(
      new ScrollMagic.Scene({
        triggerElement: '#main-content-wrapper',
        triggerHook: 'onLeave',
        duration: window.innerHeight,
      })
        .setTween([
          TweenMax.to('#main-content', 1, {
            ease,
            y: '-100%',
          }),
        ])
        .addTo(controller.current),
      new ScrollMagic.Scene({
        triggerElement: '#main-content-wrapper',
        triggerHook: 'onLeave',
        duration: window.innerHeight * 1.5,
      })
        .setTween([
          TweenMax.to('#bottom-background', 1, {
            ease,
            y: '-100%',
          }),
        ])
        .addTo(controller.current)
    );
  }

  function getSelectedWorkPB() {
    // const $el = document.getElementById('selected-works');
    // if (!$el) return 0;
    // return window.getComputedStyle($el, 'padding-bottom');
    return 150;
  }

  function getRandomArbitrary(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  function isOnLeft(element: Element): boolean {
    const half = window.innerWidth / 2;
    const offsetLeft = element.getBoundingClientRect().left;
    const elWidth = element.clientWidth;
    return offsetLeft < half && offsetLeft + elWidth / 2 < half;
  }

  function transitionDribbles() {
    const width = window.innerWidth;
    document.querySelectorAll('.image-box').forEach(function ($el, index) {
      TweenMax.to($el, 0, {
        // y: getRandomArbitrary(0, $(window).height() / 2),
        x: (width && width >= 768 ? isOnLeft($el) : index % 2 !== 0)
          ? getRandomArbitrary(-window.innerWidth, 0)
          : getRandomArbitrary(-0, window.innerWidth),
        scale: 3,
      });
    });

    const $selectedImages = document.querySelectorAll('.selected-image');

    $selectedImages.forEach(function ($el, i) {
      const children = $el.querySelectorAll('.image-box');
      const parent = $el;
      children.forEach(function ($child) {
        const tl = new TimelineLite();
        tl /*.to($child, 1, {
          scale: 2,
        })*/.to($child, 1, {
          scale: 1,
          y: 0,
          x: 0,
        });
        screens.current.push(
          new ScrollMagic.Scene({
            triggerElement: parent,
            duration:
              i === $selectedImages.length - 1
                ? $child.clientHeight * 2 + getSelectedWorkPB()
                : window.innerHeight + $child.clientHeight,
            triggerHook: 'onEnter',
            offset: -$child.clientHeight,
          })
            .setTween(tl)
            .addTo(controller.current)
        );
      });
    });
  }

  function transitionBackground() {
    screens.current.push(
      new ScrollMagic.Scene(triggerSelectedWorkConfig())
        .on('start', () => {
          setTheme(persisTheme.current === 'light' ? 'dark' : 'light');
        })
        .setTween([
          TweenMax.to('.background', duration, {
            backgroundColor: '#fff',
            ease,
          }),
          // TweenMax.to('#nav-button__line', duration, {
          //   backgroundColor: '#000',
          //   ease,
          // }),
          // TweenMax.to('#nav-button__text', duration, {
          //   color: '#000',
          //   ease,
          // }),
          // TweenMax.set('#logo', {
          //   attr: { src: '/images/logo-black.svg' },
          //   immediateRender: false,
          // }),
        ])
        .addTo(controller.current)
    );
  }

  function transitionMoreOnDribble() {
    const moreOnDribbleTl = new TimelineLite();
    moreOnDribbleTl.to('#more-on-dribble', duration, {
      css: { opacity: 1, display: 'block' },
    });
    const config =
      window.innerWidth > 768
        ? triggerSelectedWorkConfig()
        : {
            triggerElement: '#selected-works',
            triggerHook: 'onEnter',
            offset: document.getElementById('selected-works')?.clientHeight,
          };
    screens.current.push(
      new ScrollMagic.Scene(config).setTween(moreOnDribbleTl).addTo(controller.current)
    );
  }
  function resetScrollMagic() {
    if (controller.current) {
      controller.current.destroy(1);
      controller.current = null;
    }

    if (screens.current.length) {
      // screens.current.forEach(function (scene) {
      //   scene.reverse(true);
      //   // scene.destroy(1);
      // });
      screens.current = [];
    }
  }

  function getHeight(): number {
    const topBgHeight = document.getElementById('top-background')?.clientHeight || 0;
    return getViewportHeight() + offset - topBgHeight;
  }

  function initCenterBackground() {
    const containerWidth = document.getElementsByClassName('container')[0]?.clientWidth || 0;
    const ratio = 422 / 1702;
    const imageHeight = containerWidth * ratio;
    const height = getHeight();
    const numberOfImages = Math.floor(height / imageHeight);
    setNumberOfBackgrounds(numberOfImages);
    const space = (height - imageHeight * numberOfImages) / (numberOfImages + 1);
    setSpace(space);
    // const images = [];
    // for (let i = 0; i < numberOfImages; i++) {
    //   images.push(
    //     `<img alt="Dijitally" style="margin-top: ${
    //       i === 0 ? space : 0
    //     }px; margin-bottom: ${space}px" src="/images/outlined_diji.svg">`
    //   );
    // }
    const $centerBackground = document.getElementById('center-background');
    if ($centerBackground) {
      // $centerBackground.innerHTML = images.join('');
      $centerBackground.style.height = `${height}px`;
    }
  }

  function getViewportHeight() {
    if (window.innerWidth > window.innerHeight) {
      return Math.max(window.innerHeight, 750);
    }
    return window.innerHeight;
  }

  function initStyle() {
    document.querySelectorAll('.content__item').forEach(($el) => {
      ($el as HTMLElement).style.height = `${getViewportHeight()}px`;
    });
    (
      document.getElementsByClassName('viewport')[0] as HTMLElement
    ).style.height = `${getViewportHeight()}px`;
  }

  function listenLetsTalkClick() {
    const botBgHeight = document.getElementById('bottom-background')?.clientHeight || 0;
    const displayBotBgHeight = botBgHeight * displayRatioBottom;
    animation.current = new TimelineLite();
    animation.current
      .fromTo(
        '.background',
        duration,
        {
          backgroundColor: '#22d7ee',
          y: 0,
        },
        {
          backgroundColor: '#0f172a',
          y: -offset - displayBotBgHeight,
          ease,
        }
      )
      .fromTo(
        '.content',
        duration,
        {
          y: 0,
        },
        {
          y: -getViewportHeight() + offset + displayBotBgHeight,
          ease,
        },
        0
      )
      // .fromTo(
      //   '#nav-button__line',
      //   duration,
      //   {
      //     backgroundColor: '#000',
      //     ease,
      //   },
      //   {
      //     backgroundColor: '#fff',
      //     ease,
      //   },
      //   0
      // )
      // .fromTo(
      //   '#nav-button__text',
      //   duration,
      //   {
      //     text: 'Back to home',
      //     color: '#000',
      //     ease,
      //   },
      //   {
      //     color: '#fff',
      //     text: "Let's talk",
      //     ease,
      //   },
      //   0
      // )
      .fromTo(
        '.viewport',
        duration,
        { overflow: 'hidden', ease },
        {
          overflow: 'visible',
          ease,
        }
      )
      // .fromTo(
      //   '#logo',
      //   duration,
      //   { attr: { src: '/images/logo-black.svg' } },
      //   { attr: { src: '/images/logo.svg' } },
      //   0
      // )
      .play(duration);
    setIsClicked(true);
  }

  function reset() {
    setTheme('light');
    resetScrollMagic();
    animation.current?.play(duration);
    animation.current?.kill();
    animation.current = null;
  }

  function init() {
    reset();
    controller.current = new ScrollMagic.Controller();
    screens.current = [];
    initStyle();
    initCenterBackground();
    listenLetsTalkClick();
    transitionMoreOnDribble();
    transitionBackground();
    transitionDribbles();
    transitionMainBanner();
    initWords();
    setIsInitDone(true);
  }

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
    TweenMax.to('#root', duration, {
      opacity: 1,
      ease,
    });
    init();
  }, []);

  useEffect(() => {
    if (width) init();
  }, [width]);

  return (
    <>
      <Navigation
        letTalkLabel={isClicked ? "Let's talk" : 'Back to home'}
        onLetTalkClick={onLetTalkClicked}
        fixed
      />
      <div
        id="root"
        style={{
          opacity: 0,
        }}
        className={classNames(mainStyles.root, styles.root)}
      >
        <div className="c-body">
          <div>
            <div className="viewport">
              <div className="background">
                <div className="container">
                  <div id="top-background">
                    <Image src={HalfWhiteDiji} />
                  </div>
                  <div id="center-background">
                    {Array(numberOfBackgrounds)
                      .fill(undefined)
                      .map((_, i) => (
                        <div
                          key={i}
                          style={{ marginTop: i === 0 ? space : 0, marginBottom: space }}
                        >
                          <Image src={OutlinedDiji} />
                        </div>
                      ))}
                  </div>
                  <div id="bottom-background">
                    <Image src={BlueDiji} />
                  </div>
                </div>
                <div className="content">
                  <div className="content__item container container--let-talk">
                    <div className="c-main">
                      {successMessage ? (
                        <div className="c-main__heading u-marg-bottom">
                          <h2 style={{ whiteSpace: 'pre-wrap' }}>{successMessage}</h2>
                        </div>
                      ) : (
                        <>
                          <div className="c-main__heading u-marg-bottom">
                            <h2>Let&apos;s Talk</h2>
                          </div>
                          <div
                            className="
                    lets-talk__sub-title
                    c-main__sub-heading
                    +large
                    u-align-center
                  "
                          >
                            <p>We can help you to achieve results.</p>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSubmit();
                            }}
                            // onSubmit="ValidateForm(event)"
                            // action="http://www.dijitally.com/send-contact.php"
                            // name="contact-form"
                            id="contact-form"
                            // method="post"
                          >
                            <div className="c-input-group">
                              <input
                                name="firstName"
                                value={formData.firstName}
                                onChange={onChange}
                                className={classNames('c-input', {
                                  error: error.firstName,
                                })}
                                type="text"
                                placeholder="First Name"
                                id="YourCFName"
                              />
                              <input
                                name="lastName"
                                value={formData.lastName}
                                onChange={onChange}
                                className={classNames('c-input', {
                                  error: error.lastName,
                                })}
                                type="text"
                                placeholder="Last Name"
                                id="YourCLName"
                              />
                            </div>
                            <input
                              name="email"
                              value={formData.email}
                              onChange={onChange}
                              className={classNames('c-input', {
                                error: error.email,
                              })}
                              type="email"
                              placeholder="Your Email"
                              id="YourCEmail"
                            />
                            <input
                              name="subject"
                              value={formData.subject}
                              onChange={onChange}
                              className={classNames('c-input', {
                                error: error.subject,
                              })}
                              type="text"
                              placeholder="Subject"
                              id="YourCSubject"
                            />
                            <textarea
                              name="message"
                              value={formData.message}
                              onChange={onChange}
                              className={classNames('c-input', {
                                error: error.message,
                              })}
                              id="YourCMessage"
                              cols={30}
                              rows={5}
                              placeholder="Your Message"
                            />
                            <div className="c-button-container +align-right">
                              <button type="submit" className="c-button +dark">
                                {loading && <Spinner />}
                                Send Message
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    id="main-content-wrapper"
                    className="content__item content__item--bottom container container--main"
                  >
                    <div id="main-content" className="c-main +dark">
                      <div className="c-main__heading">
                        <h1 className="u-white">
                          <span>{`// Bringing ideas`}</span>
                          <br />
                          <div className="c-main__heading-line-wrapper">
                            <span>into</span>
                            <span className="c-main__heading-line"></span>
                          </div>
                        </h1>
                      </div>
                      <div className="c-main__sub-heading u-white">
                        <div className="c-main__sub-heading-left">
                          <p>
                            We are a digital agency that crafts optimized web and digital
                            experiences.
                          </p>
                        </div>
                        <div className="c-main__sub-heading-right words-wrapper">
                          {bringingIdeas?.map(({ word }, index) => (
                            <p
                              key={index}
                              className={classNames({
                                'is-visible': index === 0,
                              })}
                            >
                              {word}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="c-button +centered" onClick={onSelectsWorksButtonClick}>
                        Selected works
                      </div>
                    </div>
                  </div>
                </div>
                <div id="selected-works" className="c-body +white">
                  <div className="c-main +light">
                    <div className="c-main__heading u-marg-bottom">
                      <h2>Selected Works</h2>
                    </div>
                    <div className="c-main__sub-heading +large u-align-center">
                      <p>Check our latest works.</p>
                    </div>
                    <div id="pics" className="selected-image-container">
                      <Dribbbles dribbbleShots={dribbbleShots} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="c-header">*/}
          {/*  <a href="index.html">*/}
          {/*    <img id="logo" src="/images/logo-black.svg" alt="" />*/}
          {/*  </a>*/}
          {/*  <div id="nav-button" onClick={onLetTalkClicked}>*/}
          {/*    <a id="nav-button__text"> BACK TO HOME </a>*/}
          {/*    <div id="nav-button__line"></div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div id="more-on-dribble">
            <Link href="https://dribbble.com/dijitally" passHref>
              <a className="link-no-style u-navy">{`// More on Dribbble`}</a>
            </Link>
            <div onClick={goToTop} id="go-to-top">
              GO TO THE TOP
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DijiBlogHoldingSite;
