import PageModel from '@/models/PageModel';

export const getPageById = async (pageId?: string | string[], cb?: (page: any) => void) => {
  if (!pageId) return;
  const page = await PageModel.findPage({
    params: { modelId: Array.isArray(pageId) ? pageId[0] : pageId },
  });
  cb?.(page);
  return page;
};

export const goTo = (offset?: number, callback?: () => void) => {
  if (offset === undefined) return;
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.scrollY.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback?.();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
};
