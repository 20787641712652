import Link from 'next/link';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { DribbbleShot } from '@/components/diji-blog-holding-site/diji-blog-holding-site';
import TextSkeleton from '@/components/skeleton/text-skeleton/text-skeleton';

const Dribble = ({
  dribbbleShot,
  index,
}: {
  dribbbleShot: DribbbleShot;
  index: number;
}): JSX.Element | null => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const image = dribbbleShot?.image;
  if (!image) return null;
  return (
    <div className={`selected-image rellax +${index}`}>
      <Link href={dribbbleShot?.url || '/'} passHref>
        <a target="_blank" rel="noreferrer" className="image-box">
          <LazyLoadImage
            effect="opacity"
            wrapperClassName={'image-placeholder-lazy-container'}
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
            src={image}
            alt="Dribbble shot"
          />
          {!loaded && <TextSkeleton size={['100%', '100%']} className="image-placeholder" />}
        </a>
      </Link>
    </div>
  );
};

export default Dribble;
